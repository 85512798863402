import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { Purple, LightPink, LightBlue, Background } from '../styles/constants';
import GithubLogo from "../content/github-logo.svg"
import TwitterLogo from "../content/twitter-logo.svg"
import LinkedInLogo from "../content/linkedin-logo.svg"
import YoutubeLogo from "../content/youtube-logo.svg"

const HeroSection = styled.section`
    display: flex;
    width: 100%;
    justify-content: center;
    position: absolute;
    top: 25%;
    left: 0;

    @media only screen and (max-width: 1000px) {
        top: 10%;
    }
`;

const HeroContainer = styled.section`
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    width: 80%;

    @media only screen and (max-width: 1000px) {
        flex-direction: column;
        width: 100%;
        margin: 0 2rem 0 2rem;
    }
`;

const LeftContainer = styled.section`
    display: flex;
    flex-direction: column;
    width: 40%;

    @media only screen and (max-width: 1000px) {
        width: 100%;
    }
`
const RightContainer = styled.section`
    display: flex;
    flex-direction: column;
    width: 40%;

    @media only screen and (max-width: 1000px) {
        width: 100%;
    }
`;

const MainTitle = styled.span`
    font-size: 6rem;
    font-weight: 600;
    letter-spacing: .3rem;
    padding-bottom: 2.5rem;
    color: ${Purple};
`;

const SubTitle = styled.span`
    font-size: 2rem;
    letter-spacing: .1rem;
    color: ${LightBlue};
`;

const Description = styled.span`
    padding-top: 1rem;
    font-size: 2rem;
    padding-bottom: 2.5rem;

    @media only screen and (max-width: 1000px) {
        font-size: 1.5rem;
    }
`;

const UnderlinedPitch = styled.a`
    text-decoration: underline;
    font-size: 2rem;
    color: ${LightPink};

    @media only screen and (max-width: 1000px) {
        font-size: 1.5rem;
    }
`;

const LinkIconSection = styled.section`
    display: flex;
    justify-content: space-around;    
    width: 100%;
`;

const LogoImage = styled.img`
    width: 50px;
    height: 50px;
    padding: 1rem;
`;

const Hero = () => (
    <HeroSection>
        <HeroContainer>
            <LeftContainer>
                <MainTitle>Hey.</MainTitle>
                <SubTitle>
                    My name is Hassan Alam, and I'm a full-stack software engineer.
                </SubTitle>
            </LeftContainer>
            <RightContainer>
                <Description>
                    I’m passionate and committed to facing challenges and creating great products, by making changes that provide value to consumers. I’ve delivered numerous products to production, and will continue to do.
                </Description>
                <UnderlinedPitch href="/contact">
                    If you’d like me to help with your project or business, please don’t hesitate to contact me.
                </UnderlinedPitch>
                <LinkIconSection>
                    <a href="https://linkedin.com/in/hassan-alam"
                        target="_blank">
                        <LogoImage
                            src={LinkedInLogo}
                        />
                    </a>
                    <a href="https://github.com/hassan-alam"
                        target="_blank">
                        <LogoImage
                            src={GithubLogo}
                        />
                    </a>
                    <a href="https://twitter.com"
                        target="_blank">
                        <LogoImage
                            src={TwitterLogo}
                        />
                    </a>
                    <a href="https://youtube.com"
                        target="_blank">
                        <LogoImage
                            src={YoutubeLogo}
                        />
                    </a>
                </LinkIconSection>
            </RightContainer>
        </HeroContainer>
    </HeroSection>
);



export default Hero;